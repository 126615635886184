import React, {useEffect, useState} from 'react'
import {Elements} from '@stripe/react-stripe-js'
import {loadStripe, Stripe} from '@stripe/stripe-js'
import {useUserContext} from '../../../context/UserContext'
import {createSubscription} from '../../../service/persistenceService'
import {Box, Button, Divider, Grid, Link, Typography} from '@mui/material'
import {NarusLogo} from '../../icons/NarusLogo'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import {UserButton, useUser} from '@clerk/clerk-react'
import './Payment.scss'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {Payment2} from './Payment2'
import {EditSeatsModal} from './EditSeatsModal'
import {TrackActionEvent} from '../../../service/SegmentService'
import {stripePublishableKey} from '../../../constants'
import {usePaymentContext} from '../../../context/PaymentContext'
import {useNavigate} from 'react-router-dom'
import {getPlanPrice, PRICES_INFO_MAP} from '../../../utils/licenseUtils'

export const Payment = () => {
	const navigate = useNavigate()

	const {user} = useUser()
	const {token} = useUserContext()
	const {planId, seats, setSeats, selectedPlan, periodSelected} = usePaymentContext()


	const [stripe, setStripe] = useState<Stripe | null>(null)
	const [clientSecret, setClientSecret] = useState('')
	const [seatsModalOpen, setSeatsModalOpen] = useState(false)

	useEffect(() => {
		if (!stripePublishableKey || !planId || !selectedPlan) return navigate('/pricing')
		loadStripe(stripePublishableKey).then(stripe => setStripe(stripe))
		createSubscription(token, planId, seats).then(clientSecret => setClientSecret(clientSecret))
	}, [token, navigate, planId, seats, selectedPlan])

	const onCloseSeatsModal = () => {
		TrackActionEvent('Edit seats modal', user?.id, {action: 'close'})
		setSeatsModalOpen(false)
	}

	const price = (seats * getPlanPrice(selectedPlan, periodSelected)).toLocaleString('en-US', {style: 'currency', currency: 'USD'})

	if (!stripePublishableKey || !planId || !selectedPlan) return <></>

	return <Grid container className='paymentContainer'>
		<Grid item xs={6} className='summaryContainer'>
			<NarusLogo height={38} width={38}/>
			<Box className='titleContainer'>
				<Link onClick={() => navigate(-1)} className='goBackIcon'><ArrowBackIcon/></Link>
				<Typography variant='h3'>Summary</Typography>
			</Box>
			<Box className='purchaseDetailWrapper'>
				<Typography className='subtitle'>Subscribe to {PRICES_INFO_MAP[selectedPlan!!].name} plan</Typography>
				<Box className='totalPriceContainer'>
					<Typography className='totalPrice'>{price}</Typography>
					<Typography className='period'>Per {periodSelected === 'annual' ? 'year' : 'month'}</Typography>
				</Box>
				<Box className='purchaseDetailsContainer'>
					<Box className='purchaseRow'>
						<Typography>Plan Pro billed {periodSelected === 'annual' ? 'annually' : 'monthly'}</Typography>
						<Typography>{price}</Typography>
					</Box>
					<Button className='seatsButton' onClick={() => setSeatsModalOpen(true)}>Seats {seats} <KeyboardArrowDownIcon/></Button>
					<Divider className='purchaseDivider'/>
					<Box className='purchaseRow'>
						<Typography>Subtotal</Typography>
						<Typography>{price}</Typography>
					</Box>
					<Box className='purchaseRow'>
						<Typography>VAT (20% inclusive)</Typography>
						<Typography>$2,000.00</Typography>
					</Box>
					{/*<Divider className='purchaseDivider'/>*/}
					{/*<Typography>Discount code</Typography>*/}
					{/*<TextField className='discount' fullWidth placeholder='Add a promotion code'/>*/}
					<Divider className='purchaseDivider'/>
					<Box className='purchaseRow'>
						<Typography>Total due today</Typography>
						<Typography>$8,000.00</Typography>
					</Box>
				</Box>
			</Box>
		</Grid>
		<Grid item xs={6} className='cardContainer'>
			<Box className='userButtonContainer'>
				<UserButton showName={false}/>
			</Box>
			<Box className='cardDetailsWrapper'>
				{stripe && clientSecret && <Elements stripe={stripe} options={{clientSecret}}>
                    <Payment2 stripe={stripe}/>
                </Elements>}
			</Box>
		</Grid>
		<EditSeatsModal seatsModalOpen={seatsModalOpen} onModalClose={onCloseSeatsModal} seats={seats} setSeats={setSeats}/>
	</Grid>
}