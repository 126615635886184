import {FC} from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider} from '@mui/material'
import {USER_SUPPORT_EMAIL} from '../../constants/Links'

type Props = {
    open: boolean
    onClose: () => void
}

export const ContactSupportDialog: FC<Props> = ({
    open,
    onClose
}) => (
    <Dialog open={open} onClose={onClose}>
        <DialogTitle>
            <span>Help & Support</span>
        </DialogTitle>
        <Divider/>
        <DialogContent>
            <DialogContentText>
                Have questions or need support? Contact us at {USER_SUPPORT_EMAIL} for assistance.
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button variant='contained' onClick={onClose}>Close</Button>
        </DialogActions>
    </Dialog>
)