import {growthMonthlyPriceId, growthYearlyPriceId, scaleMonthlyPriceId, scaleYearlyPriceId} from '../constants'

export const PRICES_INFO_MAP = {
	growth: {
		name: 'Growth',
		annual: {
			priceId: growthYearlyPriceId
		},
		monthly: {
			priceId: growthMonthlyPriceId
		}
	},
	scale: {
		name: 'Scale',
		annual: {
			priceId: scaleYearlyPriceId
		},
		monthly: {
			priceId: scaleMonthlyPriceId
		}
	}
}


export const getPlanPrice = (plan: 'scale' | 'growth' | undefined, period: 'annual' | 'monthly'): number => {
	if (!plan) return 0

	if (plan === 'growth') {
		return period === 'annual' ? 3 : 3.45
	}
	return period === 'annual' ? 5 : 5.75
}