import {createContext, Dispatch, FC, ReactNode, SetStateAction, useContext, useMemo, useState} from 'react'

interface PaymentContextProviderProps {
	children: ReactNode
}

export interface PaymentContextValue {
	planId: string | undefined
	setPlanId: Dispatch<SetStateAction<string | undefined>>
	seats: number
	setSeats: Dispatch<SetStateAction<number>>
	periodSelected: 'annual' | 'monthly'
	setPeriodSelected: Dispatch<SetStateAction<'annual' | 'monthly'>>
	selectedPlan: 'growth' | 'scale' | undefined
	setSelectedPlan: Dispatch<SetStateAction<'growth' | 'scale' | undefined>>
}

const DEFAULT_PAYMENT_CONTEXT: PaymentContextValue = {
	planId: '',
	setPlanId: () => {},
	seats: 1,
	setSeats: () => {},
	periodSelected: 'annual',
	setPeriodSelected: () => {},
	selectedPlan: 'growth',
	setSelectedPlan: () => {}
}

export const PaymentContext = createContext<PaymentContextValue>(DEFAULT_PAYMENT_CONTEXT)

export const usePaymentContext = () => useContext(PaymentContext)

export const PaymentContextProvider: FC<PaymentContextProviderProps> = ({children}) => {

	const [planId, setPlanId] = useState<string>()
	const [seats, setSeats] = useState<number>(1)
	const [periodSelected, setPeriodSelected] = useState<'annual' | 'monthly'>('annual')
	const [selectedPlan, setSelectedPlan] = useState<'growth' | 'scale' | undefined>(undefined)

	const value: PaymentContextValue = useMemo(() => ({
			planId, setPlanId,
			seats, setSeats,
			periodSelected, setPeriodSelected,
			selectedPlan, setSelectedPlan
		}),
		[planId, seats, periodSelected, selectedPlan])

	return <PaymentContext.Provider value={value}>
		{children}
	</PaymentContext.Provider>
}