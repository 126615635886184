import {useUser} from '@clerk/clerk-react'
import {
    DataGrid,
    GridCallbackDetails,
    GridColDef,
    GridColumnVisibilityModel,
    GridLogicOperator,
    GridSortModel
} from '@mui/x-data-grid'
import {useEffect, useState} from 'react'
import {objectsAreEquals} from '../../helpers/ObjectHelpers'
import {TrackActionEvent} from '../../service/SegmentService'
import {GridFilterModel} from '@mui/x-data-grid/models/gridFilterModel'
import {GridPaginationModel} from '@mui/x-data-grid/models/gridPaginationProps'
import {DataGridCustomToolbar} from './charts/DataGridCustomToolbar'
import {v4 as uuidv4} from 'uuid'
import {useDataGridContext} from '../../context/DataGridContext'

export type TableName = 'AUDITING_LOGS' | 'USER_USAGE' | 'USERS_LIST' | 'GROUP_MEMBERS'

declare module '@mui/x-data-grid' {
    interface ToolbarPropsOverrides {
        currentTable: TableName
    }
}

type DataGridTableProps = {
    columns: GridColDef[]
    rows: any[]
    loading: boolean
    analyticEventName: string
    pageSize?: number
    currentTable: TableName
    fieldToSort?: string
    columnVisibilityModel?: GridColumnVisibilityModel
}

export const DataGridTable = ({columns, rows, loading, analyticEventName, pageSize, currentTable, fieldToSort, columnVisibilityModel}: DataGridTableProps) => {

    const {user} = useUser()
    const [columnVisibility, setColumnVisibility] = useState<GridColumnVisibilityModel>(columnVisibilityModel ?? {})
    const {
        dataGridState: {
            paginationModel,
            sortModel,
        }, setDataGridState
    } = useDataGridContext()

    const initialColumnsVisibility = columns.reduce(((current: Object, column: GridColDef) => {
        current[column.field] = true
        return current
    }), {})

    const [currentPage, setCurrentPage] = useState<number>(paginationModel.page)
    const [currentVisibleColumns, setVisibleColumns] = useState(initialColumnsVisibility)

    const columnsChangeHandler = (model: GridColumnVisibilityModel) => {
        setColumnVisibility(model)
        const visibleColumns = columns.reduce(((current: Object, column: GridColDef) => {
            current[column.field] = model[column.field] ?? true
            return current
        }), {})

        if (!objectsAreEquals(visibleColumns, currentVisibleColumns)) {
            TrackActionEvent(analyticEventName, user?.externalId ?? user?.id, {action: 'set_columns', visible_columns: visibleColumns})
            setVisibleColumns(visibleColumns)
        }
    }

    const filterModelChangeHandler = (filterModel: GridFilterModel, details: GridCallbackDetails) => {
        if (details.reason) {
            TrackActionEvent(analyticEventName, user?.externalId ?? user?.id, {
                action: 'filter',
                filter_fields: filterModel.items.map(item => item.field)
            })
            setDataGridState((prevState) => ({...prevState, filterModel}))
        } else {
            TrackActionEvent(analyticEventName, user?.externalId ?? user?.id, {action: 'quick_search'})
        }
    }

    const paginationModelChangeHandler = (paginationModel: GridPaginationModel) => {
        if (paginationModel.page !== currentPage) {
            TrackActionEvent(analyticEventName, user?.externalId ?? user?.id, {action: 'paginate'})
            setCurrentPage(paginationModel.page)
            setDataGridState((prevState) => ({...prevState, paginationModel}))
        }
    }

    const sortModelChangeHandler = (sortModel: GridSortModel) => {
        setDataGridState((prevState) => ({...prevState, sortModel}))
    }

    useEffect(() => {
        setColumnVisibility(columnVisibilityModel ?? {})
    }, [columnVisibilityModel])

    return <DataGrid
        rows={rows ?? []}
        columns={columns}
        getRowId={row => row.userId ?? row.groupId ?? uuidv4()}
        loading={loading}
        autoHeight={true}
        density='compact'
        onColumnVisibilityModelChange={columnsChangeHandler}
        onFilterModelChange={filterModelChangeHandler}
        initialState={{
            pagination: {
                paginationModel: {page: 0, pageSize: pageSize ?? paginationModel.pageSize},
            },
            filter: {
                filterModel: {
                    items: [],
                    quickFilterLogicOperator: GridLogicOperator.Or,
                },
            },
            sorting: {
                sortModel: fieldToSort ? [{field: fieldToSort, sort: 'desc'}] : []
            }
        }}
        columnVisibilityModel={columnVisibility}
        sx={{border: 'none'}}
        disableDensitySelector
        slots={{toolbar: DataGridCustomToolbar}}
        pageSizeOptions={[10, 20, 50]}
        onPaginationModelChange={paginationModelChangeHandler}
        onSortModelChange={sortModelChangeHandler}
        sortModel={sortModel}
        slotProps={{
            toolbar: {
                showQuickFilter: true,
                currentTable
            },
        }}
    />
}