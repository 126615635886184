import {Box, Grid} from '@mui/material'
import {DataGridTable} from '../../DataGridTable'
import {GridColDef} from '@mui/x-data-grid'
import {formatDateStringToLocaleDate} from '../../../../helpers/DateHelpers'
import './GroupTable.scss'
import {GroupMember} from '../../../../types/GroupMember'

interface GroupTableProps {
    groupMembers: GroupMember[]
}

export const GroupTable = ({groupMembers}: GroupTableProps) => {

    const columns: GridColDef[] = [
        {field: 'userFullName', headerName: 'Name', flex: 1, cellClassName: 'userInfoCell'},
        {field: 'userEmail', headerName: 'Email', flex: 1, cellClassName: 'userInfoCell'},
        {field: 'userRole', headerName: 'Role', flex: 1, cellClassName: 'userInfoCell', valueFormatter: (value: 'user' | 'admin') => value === 'admin' ? 'Admin' : 'End user'},
        {field: 'entryDate', headerName: 'Entry date', flex: 1, cellClassName: 'userInfoCell', valueFormatter: formatDateStringToLocaleDate}
    ]

    return <Grid item xs={12}>
        <Box className='teamTableContainer'>
            <DataGridTable
                fieldToSort='userFullName'
                columns={columns}
                rows={groupMembers}
                loading={false}
                analyticEventName='Groups'
                currentTable='GROUP_MEMBERS'
            />
        </Box>
    </Grid>
}