import * as Sentry from '@sentry/react'
import {EventHint, ErrorEvent} from '@sentry/react'


export const InitSentry = () => {
	Sentry.init({
		dsn: "https://7d6ff486d98c61c9b46f758333e30fd1@o82263.ingest.us.sentry.io/4508166218645504",
		integrations: [
			Sentry.browserTracingIntegration(),
			Sentry.replayIntegration(),
		],
		// Tracing
		tracesSampleRate: 1.0, //  Capture 100% of the transactions
		// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
		tracePropagationTargets: [/^https:\/\/app\.narus\.ai/],
		// Session Replay
		replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
		environment: process.env.REACT_APP_ENVIRONMENT,
		beforeSend: (event: ErrorEvent, hint: EventHint): ErrorEvent | PromiseLike<ErrorEvent | null> | null => {
			if ((hint.originalException as any)?.message === 'Network Error') {
				return null
			}
			return event
		}

	})
}