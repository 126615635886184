import {FC, PropsWithChildren} from 'react'
import {ToggleDrawerContextProvider} from '../context/ToggleDrawerContext'
import {AppNavigationContextProvider} from '../context/AppNavigationContext'
import {AppMenuWrapper} from '../components/appMenu/AppMenuWrapper'
import {AppGridWrapper} from '../components/appGridWrapper/AppGridWrapper'
import {TrialBoundary} from '../components/trial/TrialBoundary'

export const CommonConsumers: FC<PropsWithChildren> = ({
    children
}) => (
    <ToggleDrawerContextProvider>
        <AppNavigationContextProvider>
            <AppMenuWrapper>
                <AppGridWrapper>
                    <TrialBoundary>
                        {children}
                    </TrialBoundary>
                </AppGridWrapper>
            </AppMenuWrapper>
        </AppNavigationContextProvider>
    </ToggleDrawerContextProvider>
)