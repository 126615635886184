import {useCallback, useEffect, useState} from 'react'
import {useUser} from '@clerk/clerk-react'
import {Box, Button, Typography} from '@mui/material'
import {InfoScreen} from '../../common/infoScreen/InfoScreen'
import {RoundIcon} from '../../common/roundIcon/RoundIcon'
import {ContactSupportDialog} from '../../common/ContactSupportDialog'
import {PageView, TrackActionEvent} from '../../../service/SegmentService'
import ErrorIcon from '@mui/icons-material/Error'
import './PaymentError.scss'

export const PaymentError = () => {
    const {user} = useUser()

    const [dialogOpen, setDialogOpen] = useState(false)

    const userId = user?.externalId ?? user?.id

    const handleContactClick = useCallback(() => {
        TrackActionEvent('Payment Error', userId, {action: 'contact'})
        setDialogOpen(true)
    }, [userId])

    const handleTryAgainClick = useCallback(() => {
        TrackActionEvent('Payment Error', userId, {action: 'try_again'})
        // TODO: Connect with navigate to subscription payment screen
    }, [userId])

    const handleDialogClose = useCallback(() => {
        setDialogOpen(false)
    }, [])

    useEffect(() => {
        PageView('Payment Error')
    }, [])

    return (
        <InfoScreen>
            <Box className='paymentErrorContainer'>
                <RoundIcon className='paymentErrorIcon' icon={ErrorIcon} size={200} iconSize={150} colorEnd='#CA97A280' colorStart='#C1AFE580' iconColorEnd='#C1AFE580' iconColorStart='#CA97A280'/>
                <Typography className='paymentErrorTitle'>Oops! Something went wrong</Typography>
                <Typography className='paymentErrorSubtitle'>There was an error. Please try again or contact support</Typography>
                <Box className='paymentErrorActions'>
                    <Button variant='contained' onClick={handleTryAgainClick}>Try again</Button>
                    <Button variant='outlined' onClick={handleContactClick}>contact support</Button>
                </Box>
            </Box>
            <ContactSupportDialog open={dialogOpen} onClose={handleDialogClose}/>
        </InfoScreen>
    )
}