import { Box, Grid, Typography } from '@mui/material'
import './BudgetHistory.scss'
import { formatDateStringToLocaleDate } from '../../../../helpers/DateHelpers'
import { useUsersAccessContext } from '../../../../context/UsersAccessContext'
import { useBudgetsContext } from '../../../../context/BudgetContext'
import Spinner from '../../../spinner/Spinner'

const LEFT_SPACING = 3
const RIGHT_SPACING = 12 - LEFT_SPACING

export const BudgetHistory = () => {
    const {usersInfo} = useUsersAccessContext()
    const {closedBudgets, loadingClosedBudgets} = useBudgetsContext()

    return (
        <Box className='budgetHistoryContainer'>
            { loadingClosedBudgets && !closedBudgets.length ? <Spinner/> : <></> }
            { !loadingClosedBudgets && !closedBudgets.length ? <Typography className='budgetHistoryEmpty'>No closed budgets yet</Typography> : <></>}
            { closedBudgets.map(budget => (
                <Box key={budget.hashKey} className='cardContainer'>
                    <Typography className='cardHeader'>Organization budget ({formatDateStringToLocaleDate(budget.startDate)})</Typography>
                    <Grid container spacing={'9px'}>
                        <Grid item xs={LEFT_SPACING}>
                            <Typography className='cardPropertyKey'>Created</Typography>
                        </Grid>
                        <Grid item xs={RIGHT_SPACING}>
                            <Typography className='cardPropertyValue'>{usersInfo.find(user => user.userId === budget.creatorUserId)?.email ?? budget.creatorUserId }</Typography>
                        </Grid>
                        <Grid item xs={LEFT_SPACING}>
                            <Typography className='cardPropertyKey'>Date of creation</Typography>
                        </Grid>
                        <Grid item xs={RIGHT_SPACING}>
                            <Typography className='cardPropertyValue'>{formatDateStringToLocaleDate(budget.creationDate)}</Typography>
                        </Grid>
                        <Grid item xs={LEFT_SPACING}>
                            <Typography className='cardPropertyKey'>Budget amount</Typography>
                        </Grid>
                        <Grid item xs={RIGHT_SPACING}>
                            <Typography className='cardPropertyValue'>{budget.amount.toLocaleString('en-US', {style: 'currency', currency: 'USD'})}</Typography>
                        </Grid>
                        <Grid item xs={LEFT_SPACING}>
                            <Typography className='cardPropertyKey'>Recurrence</Typography>
                        </Grid>
                        <Grid item xs={RIGHT_SPACING}>
                            <Typography className='cardPropertyValue'>{budget.periodicity}</Typography>
                        </Grid>
                        <Grid item xs={LEFT_SPACING}>
                            <Typography className='cardPropertyKey'>Closing date</Typography>
                        </Grid>
                        <Grid item xs={RIGHT_SPACING}>
                            <Typography className='cardPropertyValue'>{formatDateStringToLocaleDate(budget.periodEndDate)}</Typography>
                        </Grid>
                    </Grid>
                </Box>
            )) }
        </Box>
    )   
}