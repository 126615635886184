import {GridActionsCellItem, GridColDef, GridRenderCellParams, GridRowParams} from '@mui/x-data-grid'
import {Box, Grid, Typography} from '@mui/material'
import './AuditingLogsTable.scss'
import {DataGridTable} from '../DataGridTable'
import {useAuditingLogsContext} from '../../../context/AuditingLogsContext'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import {formatDateStringToLocaleDate} from '../../../helpers/DateHelpers'
import {auditLogWarningGridValueGetter, hasViewPending} from '../../../utils/userAuditLogUtils'
import {AuditLogWarningLevel} from '../../../types/UserAuditLog'
import {useMemo} from 'react'
import VisibilityIcon from '@mui/icons-material/Visibility'
import {CustomDateRangePicker} from '../CustomDateRangePicker'
import {AuditLogWarningLevelChip} from './AuditLogWarningLevelChip'
import {useNavigate} from 'react-router'

const renderCellSaveOutput = (params: GridRenderCellParams) =>
	params.row.isOutputSaved
		? <CheckCircleIcon className='checkIcon'/>
		: <CancelIcon className='cancelIcon'/>

const renderCellWarning = ({value}: GridRenderCellParams<any, AuditLogWarningLevel>) =>
	value ? <AuditLogWarningLevelChip level={value} /> : <></>

export const AuditingLogsTable = () => {

	const {auditingLogs, loading} = useAuditingLogsContext()
	const navigate = useNavigate()

	const columns: GridColDef[] = useMemo(() => [
		{field: 'conversationDate', headerName: 'Date', flex: 1, valueFormatter: formatDateStringToLocaleDate},
		{field: 'aiModelName', headerName: 'Model', flex: 1},
		{field: 'userFullName', headerName: 'User', flex: 1},
		{field: 'messagesSentToModel', headerName: 'Messages sent', flex: 1},
		{field: 'isOutputSaved', headerName: 'Save output', flex: 1, renderCell: renderCellSaveOutput},
		{field: 'warning', headerName: 'Warning', flex: 1, valueGetter: auditLogWarningGridValueGetter, renderCell: renderCellWarning},
		{
			field: 'actions', type: 'actions', headerName: 'Actions', width: 80, getActions: (params: GridRowParams) => [params.row.warnings?.length ?
				<GridActionsCellItem
					className={hasViewPending(params.row) ? 'AuditingLogsAction_Pending' : ''}
					icon={<VisibilityIcon className='actionIcon'/>}
					label='Open audit log'
					onClick={() => navigate(`/admin/logs/${params.row.hashKey}`)}
				/> : <></>]
		}
	], [navigate])

	return (
		<Grid item xs={12} className='auditingLogsContainer'>
			<Box className='auditingLogsHeader'>
				<Typography className='auditingLogsTitle' variant='h3'>Auditing Logs</Typography>
				<CustomDateRangePicker currentTab='REPORTS'/>
			</Box>
			<DataGridTable
				fieldToSort='conversationDate'
				columns={columns}
				rows={auditingLogs ?? []}
				pageSize={25}
				loading={loading && !auditingLogs.length}
				analyticEventName='Auditing logs table'
				currentTable='AUDITING_LOGS'
			/>	
		</Grid>
	)
}