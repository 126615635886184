import {Button, Checkbox, Divider, FormControlLabel, Grid, Paper, TextField, ToggleButton, ToggleButtonGroup, Typography} from '@mui/material'
import './PricingTable.scss'
import Box from '@mui/material/Box'
import {NarusLogo} from '../../icons/NarusLogo'
import {ChangeEvent, useEffect} from 'react'
import {UserButton, useUser} from '@clerk/clerk-react'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CheckIcon from '@mui/icons-material/Check'
import RemoveIcon from '@mui/icons-material/Remove'
import AddIcon from '@mui/icons-material/Add'
import {PageView, TrackActionEvent} from '../../../service/SegmentService'
import {AppGridWrapper} from '../../appGridWrapper/AppGridWrapper'
import {useNavigate} from 'react-router-dom'
import {usePaymentContext} from '../../../context/PaymentContext'
import {getPlanPrice, PRICES_INFO_MAP} from '../../../utils/licenseUtils'

export const PricingTable = () => {

	const navigate = useNavigate()

	const {user} = useUser()
	const {seats, setSeats, setPlanId, selectedPlan, setSelectedPlan, periodSelected, setPeriodSelected} = usePaymentContext()


	useEffect(() => {
		PageView('Pricing')
	})

	const increaseSeats = () => {
		setSeats(previousSeatsNumber => {
			if (!previousSeatsNumber) return 1
			return previousSeatsNumber + 1
		})
	}

	const decreaseSeats = () => {
		setSeats(previousSeatsNumber => {
			if (!previousSeatsNumber) return 1
			return previousSeatsNumber > 1 ? previousSeatsNumber - 1 : 1
		})
	}

	const seatsChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
		const seatsNumber = Number(event.target.value)
		if (seatsNumber < 1) return setSeats(1)
		setSeats(seatsNumber)
	}

	const paymentClickHandler = () => {
		if (!selectedPlan) return
		setPlanId(PRICES_INFO_MAP[selectedPlan][periodSelected].priceId)
		TrackActionEvent('Pricing table', user?.externalId ?? user?.id, {action: 'go_payment'})
		navigate('/payment')
	}

	return <AppGridWrapper>
		<Grid container className='pricingContainer'>
			<Grid xs={12} className='headerWrapper' item>
				<Box className='headerWelcome'>
					<NarusLogo/>
				</Box>
				<UserButton showName={false}/>
			</Grid>

			<Grid item xs={12} className='titleRow'>
				<Typography variant='h3'>Select your team plan</Typography>
			</Grid>

			<Grid item xs={12} className='titleRow'>
				<ToggleButtonGroup size="small" exclusive className='periodSelector' value={periodSelected}>
					<ToggleButton value="monthly" onClick={() => setPeriodSelected('monthly')}>
						Monthly
					</ToggleButton>
					<ToggleButton value="annual" onClick={() => setPeriodSelected('annual')}>
						Annual
					</ToggleButton>
				</ToggleButtonGroup>
			</Grid>

			<Grid item xs={12} className='plansRow'>
				<Paper className={`planContainer ${selectedPlan === 'growth' ? 'selected' : ''}`}>
					<Box className='titleContainer'>
						<FormControlLabel control={<Checkbox className='planCheckbox' icon={<RadioButtonUncheckedIcon/>} onClick={() => setSelectedPlan('growth')}
						                                     checkedIcon={<CheckCircleIcon/>} checked={selectedPlan === 'growth'}/>} className='planTitle' label='Growth'/>
					</Box>
					<Typography className='price'>${getPlanPrice('growth', periodSelected)} user/month</Typography>

					<Typography className='benefitTitle'>For knowledge workers:</Typography>
					<Box className='benefit'>
						<CheckIcon/>
						<Typography>Prompt optimization</Typography>
					</Box>
					<Box className='benefit'>
						<CheckIcon/>
						<Typography>Prompt libraries</Typography>
					</Box>
					<Box className='benefit'>
						<CheckIcon/>
						<Typography>Use multiple AI models</Typography>
					</Box>


					<Typography className='benefitTitle'>For administrator:</Typography>
					<Box className='benefit'>
						<CheckIcon/>
						<Typography>Connect your own APIs</Typography>
					</Box>
					<Box className='benefit'>
						<CheckIcon/>
						<Typography>AI cost monitoring</Typography>
					</Box>
					<Box className='benefit'>
						<CheckIcon/>
						<Typography>User activity by model</Typography>
					</Box>
					<Box className='benefit'>
						<CheckIcon/>
						<Typography>Audit log (3 months of audit history)</Typography>
					</Box>
				</Paper>
				<Paper className={`planContainer ${selectedPlan === 'scale' ? 'selected' : ''}`}>
					<Box className='titleContainer'>
						<FormControlLabel control={<Checkbox className='planCheckbox' icon={<RadioButtonUncheckedIcon/>} onClick={() => setSelectedPlan('scale')}
						                                     checkedIcon={<CheckCircleIcon/>} checked={selectedPlan === 'scale'}/>} label='Scale' className='planTitle'/>
					</Box>
					<Typography className='price'>${getPlanPrice('scale', periodSelected)} user/month</Typography>

					<Typography className='benefitTitle'>Everything in Growth plus:</Typography>
					<Box className='benefit'>
						<CheckIcon/>
						<Typography>Safeguarding</Typography>
					</Box>
					<Box className='benefit'>
						<CheckIcon/>
						<Typography>Unlimited LLM's</Typography>
					</Box>

					<Typography className='benefitTitle'>Services</Typography>
					<Box className='benefit'>
						<CheckIcon/>
						<Typography>Advanced SLA</Typography>
					</Box>
					<Box className='benefit'>
						<CheckIcon/>
						<Typography>Priority support queue</Typography>
					</Box>
					<Box className='benefit'>
						<CheckIcon/>
						<Typography>Onboarding package for your organisation</Typography>
					</Box>
					<Box className='benefit'>
						<CheckIcon/>
						<Typography>Dedicated customer success manager</Typography>
					</Box>
				</Paper>
				<Divider className='verticalDivider'/>
				<Box className='summaryContainer'>
					<Typography className='title'>Seats</Typography>
					<Box className='seatsRow'>
						<TextField variant='outlined' placeholder='Number of seats' value={seats} onChange={seatsChangeHandler}/>
						<Button className='subtractButton' variant='outlined' onClick={decreaseSeats}>
							<RemoveIcon className='subtractIcon'/>
						</Button>
						<Button className='addButton' variant='outlined' onClick={increaseSeats}>
							<AddIcon className='addIcon'/>
						</Button>
					</Box>

					<Divider className='horizontalDivider'/>
					<Typography className='title summaryTitle'>Summary</Typography>
					{seats && seats > 250 ?
						<>
							<Typography className='contactSalesText'>It looks like you have a big team, please contact with our sales team to get support.</Typography>
							<Button variant='outlined' className='contactSalesButton'>Contact sales</Button>
						</>
						: <>
							<Typography>{selectedPlan ? `Plan ${selectedPlan === 'growth' ? 'Growth' : 'Scale'} paid ${periodSelected === 'annual' ? 'annually' : 'monthly'}` : ''}</Typography>
							<Typography>{seats ? `${seats} seats` : ''}</Typography>
							<Typography className='price'>{((seats ?? 0) * getPlanPrice(selectedPlan, periodSelected)).toLocaleString('en-US', {
								style: 'currency',
								currency: 'USD'
							})}</Typography>
						</>}


					<Box className='buttonContainer'>
						<Button variant='contained' className='continueButton' disabled={!seats || !selectedPlan || (!!seats && seats > 250)}
						        onClick={paymentClickHandler}>Payment</Button>
					</Box>
				</Box>
			</Grid>
		</Grid>
	</AppGridWrapper>
}