import React, {useState} from 'react'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid, Tooltip,
    Typography
} from '@mui/material'
import CustomSlider from '../promptForm/customSlider/CustomSlider'
import './AiAdvancedSettingsDialog.scss'
import {usePromptCreationContext} from '../../context/PromptCreationContext'
import HelpIcon from '@mui/icons-material/Help'
import {AiAdvancedSettingsDialogProps} from '../../types/AiAdvancedSettingsDialogProps'

export const AiAdvancedSettingsDialog = ({showSliders, setShowSliders}: AiAdvancedSettingsDialogProps) => {

    const {
        temperature, setTemperature,
        setValueHandler,
        frequencyPenalty, setFrequencyPenalty
    } = usePromptCreationContext()

    const [hasSavedSettings, setHasSavedSettings] = useState<boolean>(false)

    const saveSettingsHandler = () => {
        setHasSavedSettings(true)
        setShowSliders(false)
    }

    const closeAdvancedSettingsHandler = () => {
        if (!hasSavedSettings) {
            setTemperature('0.75')
            setFrequencyPenalty('0')
        }
        setShowSliders(false)
    }

    return <Dialog onClose={() => setShowSliders(false)} open={showSliders} className='advancedSettingsDialogContainer'>
        <DialogTitle>Advanced Settings</DialogTitle>
        <DialogContent>
            <DialogContentText>Fine-tune Creativity and Language complexity to adjust your output’s inventiveness and conciseness accordingly.</DialogContentText>
        </DialogContent>
        <DialogActions className='advancedSettingsActionsContainer'>
            <Grid container>
                <Grid xs={12} md={6} item className='slider advancedSettingsColumn'>
                    <Typography>Creativity<Tooltip
                        title='Adjusts creativity and randomness in AI responses. Higher temperature is more creative but less focused. Lower temperature is more precise but less varied.'><HelpIcon
                        className='helpIcon'
                        fontSize='inherit'/></Tooltip></Typography>
                    <CustomSlider defaultValue={temperature} endNumber={1.5} startNumber={0} step={0.1}
                                  marks={[{label: '0', value: 0}, {label: '0.5', value: 0.5}, {label: '1', value: 1}, {label: '1.5', value: 1.5}]}
                                  onChangeValue={(event: Event) => setValueHandler(setTemperature, (event.target as HTMLInputElement)?.value?.toString() ?? temperature, 'temperature')}
                                  sliderKey='slider-temperature'/>
                </Grid>
                <Grid xs={12} md={6} item className='slider advancedSettingsColumn'>
                    <Typography>Language complexity<Tooltip
                        title='Controls the mix of common and uncommon words. Increase for more unusual words, and decrease for more everyday words.'><HelpIcon
                        className='helpIcon'
                        fontSize='inherit'/></Tooltip></Typography>
                    <CustomSlider defaultValue={frequencyPenalty} endNumber={2} startNumber={-2} step={0.1}
                                  marks={[{label: '-2', value: -2}, {label: '-1', value: -1}, {label: '0', value: 0}, {label: '1', value: 1}, {label: '2', value: 2}]}
                                  onChangeValue={(event: Event) => setValueHandler(setFrequencyPenalty, (event.target as HTMLInputElement)?.value?.toString() ?? frequencyPenalty, 'frequencyPenalty')}
                                  sliderKey='slider-frequencyPenalty'/>
                </Grid>
            </Grid>
        </DialogActions>
        <DialogActions className='advancedSettingsButtons'>
            <Button className='advancedSettingsCloseButton' variant='outlined'
                    onClick={closeAdvancedSettingsHandler}>CLOSE</Button>
            <Button variant='outlined'
                    className='advancedSettingsSaveButton'
                    onClick={saveSettingsHandler}>SAVE</Button>
        </DialogActions>
    </Dialog>
}