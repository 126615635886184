import {ChangeEvent, useEffect, useState} from 'react'
import {Alert, Badge, Box, Button, Grid, TextField, Typography} from '@mui/material'
import {useUserContext} from '../../context/UserContext'
import {useUser} from '@clerk/clerk-react'
import {AnalyticsIdentify, GroupEvent, PageView, TrackActionEvent,} from '../../service/SegmentService'
import {HomeTabs} from '../../components/homeTabs/HomeTabs'
import {AiModelSelector} from '../../components/aiModelSelector/AiModelSelector'
import {Sticky} from '../../components/Sticky/Sticky'
import {useSearchContext} from '../../context/SearchContext'
import {useAiModelsContext} from '../../context/AIModelsContext'
import {useUserInfoContext} from '../../context/UserInfoContext'
import {useNavigate} from 'react-router-dom'
import {isModelEnabledForUser} from '../../helpers/AiModelHelper'
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh'
import {OptimizationSettingsDialog} from '../../components/aiAdvancedSettings/OptimizationSettingsDialog'
import InfoIcon from '@mui/icons-material/Info'
import {getSelectedModelIds, isAnyModelSelected} from '../../utils/aiModelUtils'
import {PromptChatDetail} from '../../types/PromptChatDetail'
import {usePromptCreationContext} from '../../context/PromptCreationContext'
import {useDebouncedCallback} from 'use-debounce'
import Spinner from '../../components/spinner/Spinner'
import EmptyPromptsList from '../../components/emptyPromptsList/EmptyPromptsList'
import {PromptsCards} from '../../components/promptsCards/PromptsCards'
import {useUserGroupContext} from '../../context/UserGroupContext'
import {useUserBudgetContext} from '../../context/UserBudgetContext'
import {useUserOrganizationContext} from '../../context/UserOrganizationContext'
import {PromptSearchBarHomeDisabled} from '../../components/promptSearchBarHome/PromptSearchBarHomeDisabled'
import './PromptsList.scss'

export const PromptsList = () => {

	const {aiModels, loading: loadingAiModels} = useAiModelsContext()
	const {userInfo, loading: loadingUserInfo, updateUserModels} = useUserInfoContext()
	const {initialAnalyticTracked, setInitialAnalyticTracked} = useUserContext()
	const {userGroup} = useUserGroupContext()
	const {user} = useUser()
	const {hasBudgetRemaining} = useUserBudgetContext()
	const {userOrganization} = useUserOrganizationContext()

	const {
		searchLoading,
		searchedPrompts,
		lastEvaluatedKeySearch,
		setSearchLoading,
		setSearchText,
		modelsSelected,
		isDefaultSearch,
		searchUserPrompts,
		clearSearch,
		isLongTextSearch
	} = useSearchContext()

	const {
		audience, tone, format, language, modelId, userPrompt, isOptimizedPrompt, setModelId,
		setValueHandler, setModelIds, setUserPrompt, setPromptChats
	} = usePromptCreationContext()

	const navigate = useNavigate()

	const [showOptimizePromptSettings, setShowOptimizePromptSettings] = useState(false)

	useEffect(() => {
		if (!initialAnalyticTracked) {
			setInitialAnalyticTracked(true)
			PageView('Narus')
			AnalyticsIdentify(user, userOrganization.organizationId)
			GroupEvent(user?.externalId ?? user?.id)
		}
	}, [user, initialAnalyticTracked, setInitialAnalyticTracked, aiModels, navigate, userInfo?.disabledAIModels, userOrganization.organizationId])

	useEffect(() => {
		if (!loadingAiModels && !loadingUserInfo) {
			const enabledModels = aiModels?.filter(model => model.isEnabled)
			if (!enabledModels?.length || !enabledModels.some(model => isModelEnabledForUser(model.id, aiModels, userInfo, userGroup))) {
				navigate('/noModelsEnabled')
			}
		}
	}, [aiModels, navigate, userInfo, loadingUserInfo, loadingAiModels, userGroup])

	const isSendPromptDisabled = !userPrompt || !isAnyModelSelected(modelsSelected)
	const showSelectPromptModelTooltip = !!userPrompt && !isAnyModelSelected(modelsSelected)

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		const inputText = event.target.value
		setUserPrompt(inputText)
		setSearchText(inputText)
		setSearchLoading(inputText.length > 0)
	}

	const createPromptHandler = async () => {
		const createPromptAnalyticsInfo = {
			model: modelId,
			action: 'done'
		}

		if (tone) createPromptAnalyticsInfo['tone'] = tone
		if (audience) createPromptAnalyticsInfo['audience'] = audience
		if (format) createPromptAnalyticsInfo['format'] = format
		TrackActionEvent('Create prompt', user?.externalId ?? user?.id, createPromptAnalyticsInfo)

		const models = getSelectedModelIds(modelsSelected)
		setValueHandler(setModelIds, models, 'modelIds')
		setValueHandler(setModelId, models[0], 'modelId')

		if (userInfo) updateUserModels(models)

		setPromptChats(models.map(modelId => ({
			modelId,
			messages: !isOptimizedPrompt() ? [{sender: 'user', text: userPrompt, optimized: false}] : []
		})) as PromptChatDetail[])

		clearSearch()
		if (!isOptimizedPrompt()) setUserPrompt('')
		navigate(isOptimizedPrompt() ? '/aiChat/optimized' : '/aiChat')
	}

	const searchHandler = useDebouncedCallback(async (event) => {
		if (event.key === 'Enter' && !event.shiftKey) {
			event.preventDefault()
			await createPromptHandler()
			return
		}

		if (isDefaultSearch) {
			setSearchLoading(false)
			clearSearch()
			return
		}
		searchUserPrompts(true)
		TrackActionEvent('Search', user?.externalId ?? user?.id, {action: 'search'})
	}, 1000)

	const displaySearchResult = () => {
		if (searchLoading) return <Box className='noPrompts'>We’re searching for prompts... <Spinner/></Box>
		else if (searchedPrompts.length === 0) return <EmptyPromptsList message='Please type in the search bar to get results.'/>
		return <PromptsCards prompts={searchedPrompts} showLoadMore={lastEvaluatedKeySearch !== undefined}/>
	}

	const handleOptimizationSettingsDialogClosed = () => {
		setShowOptimizePromptSettings(false)
	}

	return <>
		<Grid container className='promptsListWrapper'>
			<Grid xs={12} item>
				<Box className='homeTitleContainer'>
					<Typography variant='h3'>Start your prompting journey</Typography>
					<Typography>Search, create and optimize prompts for higher quality outputs</Typography>
				</Box>
				{hasBudgetRemaining ? <Sticky position='top' unstuckClasses='' stuckClasses='searchBarContainerStuck'>
					<Box className='promptInputContainer'>
						<AiModelSelector highlightModelSelector={showSelectPromptModelTooltip} disabled={false}/>
						<TextField
							className='searchBar'
							multiline
							onChange={handleChange}
							onKeyDown={searchHandler}
							placeholder='Write your prompt here'
						/>
						{showSelectPromptModelTooltip ? <Box className='selectModelTooltip'>
							<InfoIcon/>
							<Typography>Please select a AI model to send the prompt</Typography>
						</Box> : <></>}

						<Box className='promptSettingsRow'>
							<svg width={0} height={0}>
								<linearGradient id="promptOptimizationGradient" x1="11.9951" y1="-15.2656" x2="12.2134" y2="22.905" gradientUnits="userSpaceOnUse">
									<stop stopColor="#5A8287"/>
									<stop offset="0.528562" stopColor="#50939C"/>
									<stop offset="0.695" stopColor="#7190BC"/>
									<stop offset="0.95" stopColor="#A07EE3"/>
								</linearGradient>
							</svg>
							<Button onClick={() => setShowOptimizePromptSettings(true)}>
								<Badge color='secondary' className='optimizationBadge' badgeContent={[format, tone, language].filter(optimizationParam => optimizationParam).length}>
									<AutoFixHighIcon className='promptOptimizationIcon'/>
								</Badge>
								<Typography className='optimizationText'>Prompt optimization</Typography>
							</Button>
							<Button variant='contained' className='sendButton' disabled={isSendPromptDisabled} onClick={createPromptHandler}>Send to AI</Button>
						</Box>
						<OptimizationSettingsDialog showSliders={showOptimizePromptSettings} onClose={handleOptimizationSettingsDialogClosed}/>
					</Box>
				</Sticky> : <PromptSearchBarHomeDisabled/>}
				{userPrompt ?
					<Box className='searchResultsContainer'>
						<Typography variant='h4'>Narus library search results</Typography>
						<Alert severity='info' className='fixedInfoAlert'>Library results may be inaccurate with long
							texts.</Alert>
						{isLongTextSearch ?
							<Typography variant='body2'>The results of the search might not be accurate for long prompts. Type a
								shorter one for better results.</Typography> : displaySearchResult()}
					</Box>
					: <HomeTabs/>}
			</Grid>
		</Grid>
	</>
}
